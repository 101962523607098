<template>
  <div class="forbidden pa-8">
    <v-card flat>
      <v-card-text>
        <div class="d-flex align-center">
          <v-icon left class="text-h3">mdi-shield-outline</v-icon>
          <h1 class="text-h3">Доступ запещен</h1>
        </div>

        <p>Извините, но у вас нет прав для доступа к этой странице</p>
      </v-card-text>

      <v-card-actions>
        <v-btn @click="$router.back()" depressed color="primary">
          <v-icon left>mdi-arrow-left</v-icon>
          Вернуться назад
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Forbidden"
};
</script>
